import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import logo from './logo.svg'
import reportWebVitals from './reportWebVitals';
const App = React.lazy(() => import('./App'));
ReactDOM.render(
  <React.StrictMode>
     <Suspense fallback={<div style={{width:'100vw',height:'100vh',position:'absolute', background:'rgba(0,0,0,0.5)',display:'flex',justifyContent:'center',alignItems:'center',overflow:'hidden',top:0}}><img width='200' src={logo} className='App-logo-spin'/></div>}>
     <App />
     </Suspense>
     
     
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
